// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainLayout_6i00m{display:flex;flex-direction:column;min-height:100vh}.main_VlTmS{background-color:#f1f1f4;display:flex;min-height:-webkit-fill-available;padding-bottom:8rem;padding-top:4rem;width:100%}.content_7KVvd,.main_VlTmS{flex:1 1 auto;position:relative}.content_7KVvd{width:calc(100% - 34.5rem)}.tagsWrapper_8CddW{background-color:#f1f1f4;display:flex;justify-content:space-between;padding-top:4rem;width:100%}@media(max-width:1023.98px){.tagsWrapper_8CddW{padding:2rem 0 0}}.tags_cZtqi{max-width:calc(100% - 34rem);width:100%}@media(max-width:1023.98px){.tags_cZtqi{max-width:100%}}.aside_IxGsP{flex:0 1 auto;margin-left:20px}@media(max-width:1023.98px){.aside_IxGsP{display:none}}.search_GNNhY{max-width:32.5rem}@media(max-width:1023.98px){.search_GNNhY{display:none}}.modal_v-pFJ{bottom:0;left:0;position:fixed;right:0;top:0;z-index:1000}.insight_J4UAt{transition:bottom .3s ease}@media(max-width:1279.98px){.insight_J4UAt._media_0TQh0{display:none}}@media(max-width:767.98px){.hasTopContent_sGokv{transform:translateY(-100px)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"MainLayout": "MainLayout_6i00m",
	"main": "main_VlTmS",
	"content": "content_7KVvd",
	"tagsWrapper": "tagsWrapper_8CddW",
	"tags": "tags_cZtqi",
	"aside": "aside_IxGsP",
	"search": "search_GNNhY",
	"modal": "modal_v-pFJ",
	"insight": "insight_J4UAt",
	"_media": "_media_0TQh0",
	"hasTopContent": "hasTopContent_sGokv"
};
module.exports = ___CSS_LOADER_EXPORT___;
