// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Error_8VH4J .container_uOV9b{display:flex;flex-direction:column;height:100%;justify-content:space-between;min-height:calc(100vh - 11.2rem);overflow:hidden;padding:4rem;position:relative}.Error_8VH4J .container_uOV9b .title_dftOc{font-size:3.6rem;font-size:6.4rem;font-weight:700;letter-spacing:-.04em;line-height:1.1em;line-height:6.4rem;margin-bottom:22rem;text-transform:uppercase}.Error_8VH4J .container_uOV9b .info_k\\+es\\+,.Error_8VH4J .container_uOV9b .title_dftOc{position:relative;width:-webkit-max-content;width:max-content;z-index:1}.Error_8VH4J .container_uOV9b .image_MJNSB{height:100%;left:50%;object-fit:contain;opacity:.5;position:absolute;top:0;transform:translate(-50%);width:55.8rem;z-index:0}.Error_8VH4J .container_uOV9b .description_2q7ds{color:#73788c;font-size:1.8rem;font-weight:400;letter-spacing:-.04em;line-height:120%}.Error_8VH4J .container_uOV9b .button_brXpb{margin-top:3.2rem}@media(max-width:1279.98px){.Error_8VH4J .container_uOV9b{min-height:calc(100vh - 5.6rem);padding:3.2rem}.Error_8VH4J .container_uOV9b._502_WhrNX br{display:none}.Error_8VH4J .container_uOV9b .description_2q7ds{font-size:1.6rem}.Error_8VH4J .container_uOV9b .info_k\\+es\\+,.Error_8VH4J .container_uOV9b .title_dftOc{width:100%}.Error_8VH4J .container_uOV9b .image_MJNSB{max-width:100%;width:unset}.Error_8VH4J .container_uOV9b .button_brXpb{width:100%}}@media(max-width:767.98px){.Error_8VH4J .container_uOV9b{padding:2rem}.Error_8VH4J .container_uOV9b .title_dftOc{font-size:4.8rem;font-weight:700;letter-spacing:-.04em;line-height:1.1em;text-transform:uppercase}.Error_8VH4J .container_uOV9b .description_2q7ds br{display:none}.Error_8VH4J .container_uOV9b .button_brXpb{margin-top:2rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"Error": "Error_8VH4J",
	"container": "container_uOV9b",
	"title": "title_dftOc",
	"info": "info_k+es+",
	"image": "image_MJNSB",
	"description": "description_2q7ds",
	"button": "button_brXpb",
	"_502": "_502_WhrNX"
};
module.exports = ___CSS_LOADER_EXPORT___;
