
// vuex
import { mapActions, mapGetters, mapState } from 'vuex';

// Utils
import {
    handleAddScripts,
    scriptsCommonList,
} from '@@/common/assets/js/utils/scripts';
import { otherScripts } from 'portal/config/head/scripts';
import { throttle } from '@@/common/assets/js/utils/common-utils';

// components
import TheModal from '~/components/layout/TheModal';
import TheFooter from '../components/layout/TheFooter';
import TheHeader from '~/components/layout/TheHeader.vue';
import TheBottomNavbar from '~/components/layout/TheBottomNavbar';
import TheInsight from 'portal/components/common/TheInsight';
import TheBottomMobileNavbar
    from 'portal/components/layout/TheBottomMobileNavbar.vue';
import TheToast from 'portal/components/layout/TheToast.vue';

export default {
    name: 'DefaultLayout',

    components: {
        TheToast,
        TheBottomMobileNavbar,
        TheInsight,
        TheBottomNavbar,
        TheHeader,
        TheModal,
        TheFooter,
    },

    data() {
        return {
            widgetBottomPosition: 20,
            youtubeVideos: [],
            throttleScroll: throttle(v => this.scrollHandler(v), 100),
            // routes to exclude top widget
            routesWithoutWidget: ['/rating', '/job'],
            userIsLoading: true,
            hasTopContent: false,
        };
    },

    fetchOnServer: false,

    async fetch() {
        try {
            const res = await this.$axios.$get(this.$api.media.youtube);

            this.youtubeVideos = res?.results || [];
        } catch (error) {
            console.warn('[default/fetch] request failed: ', error);
        }
    },

    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.pageUrl,
                },
            ],

            meta: [
                {
                    hid: 'og:type',
                    name: 'og:type',
                    content: 'website',
                },
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: this.pageUrl,
                },
                {
                    hid: 'twitter:url',
                    name: 'twitter:url',
                    content: this.pageUrl,
                },
            ],

            script: [
                // Metrics
                this.getYaMetricsObserverState && scriptsCommonList[0],
                otherScripts.YandexCaptcha,


            ],
        };
    },

    computed: {
        ...mapGetters({
            getYaMetricsObserverState: 'device/getYaMetricsObserverState',
            domain: 'domain/getDomainAddress',
        }),

        ...mapState({
            user: state => state.user?.user,
            topContentHeight: state => state.header.topContentHeight,
        }),

        pageUrl() {
            return `${this.domain}${this.$route.fullPath}`;
        },

        showWidget() {
            // Здесь может быть условие для отображение на отдельных страницах
            const path = this.$route.path;
            const excludeRoute = this.routesWithoutWidget.find(item => path.startsWith(`${item}`));
            return Boolean(!excludeRoute && this.youtubeVideos.length);
        },

        insightClasses() {
            return {
                [this.$style.insight]: true,
                [this.$style.hasTopContent]: !this.hasTopContent,
            };
        },

        deviceIsMobile() {
            return this.$deviceIs.mobile;
        },


        widgetBottomPositionStyle() {
            return { bottom: !this.deviceIsMobile ? `${this.widgetBottomPosition}px` : 'auto' };
        },
    },

    watch: {
        showWidget() {
            this.setWidgetHeight();
        },
    },

    beforeDestroy() {
        window.removeEventListener('scroll', () => this.throttleScroll(true));
        window.removeEventListener('scrollend', () => this.throttleScroll(false));
    },

    async mounted() {
        if (!this.user) {
            await this.fetchUser();
        }

        this.userIsLoading = false;

        if (this.getYaMetricsObserverState) {
            const filteredScripts = [...scriptsCommonList];
            filteredScripts.shift();
            handleAddScripts(filteredScripts);
        } else {
            handleAddScripts();
        }

        this.$nextTick(() => {
            const touchSupported = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0;

            this.setTouchSupport(touchSupported);
        });

        this.calcWidgetPosition();

        window.addEventListener('scroll', () => this.throttleScroll(true));
        window.addEventListener('scrollend', () => this.throttleScroll(false));
    },

    methods: {
        ...mapActions({
            setTouchSupport: 'device/changeIsTouch',
            setScrolled: 'header/setScrolled',
            fetchUser: 'user/fetchUser',
        }),

        calcWidgetPosition() {
            if (!this.$refs.footer) {
                return;
            }
            const defaultBottom = 15;
            const footer = this.$refs.footer.$refs.bottom;
            const footerRect = footer.getBoundingClientRect();
            const footerVisibleHeight = window.innerHeight - footerRect.top;

            if (footerVisibleHeight > 0) {
                this.widgetBottomPosition = Math.max(defaultBottom, footerVisibleHeight + defaultBottom);
            } else {
                this.widgetBottomPosition = defaultBottom;
            }
        },

        setWidgetHeight() {
            if (!this.showWidget) {
                this.hasTopContent = false;
                return;
            }
            const isMoreMenuHeight = document.documentElement.scrollTop >= 200;
            if (isMoreMenuHeight) {
                if (document.documentElement.scrollTop > this.lastScroll + 56) {
                    this.hasTopContent = false;
                } else if (!this.hasTopContent && this.lastScroll - document.documentElement.scrollTop > 20) {
                    this.hasTopContent = true;
                }
                this.lastScroll = document.documentElement.scrollTop;
            } else {
                this.hasTopContent = true;
            }
        },

        scrollHandler(v) {
            this.setScrolled(v);
            this.setWidgetHeight();
            this.calcWidgetPosition();
        },
    },
};
