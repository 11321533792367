
// vuex
import { mapActions, mapGetters, mapState } from 'vuex';
// Utils

import { handleAddScripts, scriptsCommonList } from '@@/common/assets/js/utils/scripts';
import { throttle } from '@@/common/assets/js/utils/common-utils';
import { otherScripts } from 'portal/config/head/scripts';


// components
import TheModal from '~/components/layout/TheModal';
import TheFooter from '../components/layout/TheFooter';
import TheHeader from '~/components/layout/TheHeader.vue';
import TheBottomNavbar from '~/components/layout/TheBottomNavbar';
import TheInsight from 'portal/components/common/TheInsight';
import NewsAside from '../components/common/news/NewsAside.vue';
import CategoryTags from '../components/common/category-tags/CategoryTags.vue';
import TheBottomMobileNavbar
    from 'portal/components/layout/TheBottomMobileNavbar.vue';
import TheToast from 'portal/components/layout/TheToast.vue';
import SearchBlock from '../components/common/SearchBlock.vue';

export default {
    name: 'MainLayout',

    components: {
        TheToast,
        TheBottomMobileNavbar,
        TheInsight,
        TheBottomNavbar,
        TheHeader,
        TheModal,
        TheFooter,
        NewsAside,
        CategoryTags,
        SearchBlock,
    },

    data() {
        return {
            widgetBottomPosition: 20,
            youtubeVideos: [],
            userIsLoading: true,
            widgetTopHeight: 56,
            lastScroll: 0,
            routesWithoutWidget: ['/rating', '/job'],
            hasTopContent: false,
        };
    },

    async fetch() {
        try {
            const res = await this.$axios.$get(this.$api.media.youtube);

            this.youtubeVideos = res?.results || [];
        } catch (error) {
            console.warn('[default/fetch] request failed: ', error);
        }
    },

    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.pageUrl,
                },
            ],

            meta: [
                {
                    hid: 'og:type',
                    name: 'og:type',
                    content: 'website',
                },
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: this.pageUrl,
                },
                {
                    hid: 'twitter:url',
                    name: 'twitter:url',
                    content: this.pageUrl,
                },
            ],

            script: [
                // Metrics
                this.getYaMetricsObserverState && scriptsCommonList[0],
                otherScripts.YandexCaptcha,
            ],
        };
    },

    computed: {
        ...mapGetters({
            getYaMetricsObserverState: 'device/getYaMetricsObserverState',
            domain: 'domain/getDomainAddress',
            mediaSpecs: 'media/getSpecs',
        }),

        ...mapState({
            isVisible: state => state.header.isVisible,
            user: state => state.user?.user,
        }),

        pageUrl() {
            return `${this.domain}${this.$route.fullPath}`;
        },

        categoryTags() {
            return this.mediaSpecs['categories_tabs'].map(tag => ({
                ...tag,
                slug: tag.slug_value,
            }));
        },

        showWidget() {
            // Здесь может быть условие для отображение на отдельных страницах
            const path = this.$route.path;
            const excludeRoute = this.routesWithoutWidget.find(item => path.startsWith(`${item}`));
            return Boolean(!excludeRoute && this.youtubeVideos.length);
        },

        deviceIsMobile() {
            return this.$deviceIs.mobile;
        },

        widgetBottomPositionStyle() {
            return { bottom: !this.deviceIsMobile ? `${this.widgetBottomPosition}px` : 'auto' };
        },

        insightClasses() {
            return {
                [this.$style.insight]: true,
                [this.$style.hasTopContent]: !this.hasTopContent,
            };
        },
    },

    watch: {
        showWidget() {
            this.setWidgetHeight();
        },
    },

    async mounted() {
        if (!this.user) {
            await this.fetchUser();
        }

        this.userIsLoading = false;

        if (this.getYaMetricsObserverState) {
            const filteredScripts = [...scriptsCommonList];
            filteredScripts.shift();
            handleAddScripts(filteredScripts);
        } else {
            handleAddScripts();
        }

        this.$nextTick(() => {
            const touchSupported = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0;

            this.setTouchSupport(touchSupported);
        });

        this.calcWidgetPosition();

        window.addEventListener('scroll', () => this.throttleScroll(true));
        window.addEventListener('scrollend', () => this.throttleScroll(false));
    },

    beforeDestroy() {
        window.removeEventListener('scroll', () => this.throttleScroll(true));
        window.removeEventListener('scrollend', () => this.throttleScroll(false));
    },

    methods: {
        ...mapActions({
            setTouchSupport: 'device/changeIsTouch',
            setScrolled: 'header/setScrolled',
            fetchUser: 'user/fetchUser',
        }),

        throttleScroll: throttle(function(v) {
            this.scrollHandler(v);
        }, 100),

        calcWidgetPosition() {
            if (!this.$refs.footer) {
                return;
            }
            const defaultBottom = 15;
            const footer = this.$refs.footer.$refs.bottom;
            const footerRect = footer.getBoundingClientRect();
            const footerVisibleHeight = window.innerHeight - footerRect.top;
            if (footerVisibleHeight > 0) {
                this.widgetBottomPosition = Math.max(defaultBottom, footerVisibleHeight + defaultBottom);
            } else {
                this.widgetBottomPosition = defaultBottom;
            }
        },

        setWidgetHeight() {
            if (!this.showWidget) {
                this.hasTopContent = false;
                return;
            }
            const isMoreMenuHeight = document.documentElement.scrollTop >= 200;
            if (isMoreMenuHeight) {
                if (document.documentElement.scrollTop > this.lastScroll + 56) {
                    this.hasTopContent = false;
                } else if (!this.hasTopContent && this.lastScroll - document.documentElement.scrollTop > 20) {
                    this.hasTopContent = true;
                }
                this.lastScroll = document.documentElement.scrollTop;
            } else {
                this.hasTopContent = true;
            }
        },

        scrollHandler(v) {
            this.setWidgetHeight();
            this.setScrolled(v);
            this.calcWidgetPosition();
        },

        openNewsModal() {
            this.$modal.open(() => import('portal/components/modals/NewsAsideModal'));
        },
    },
};
