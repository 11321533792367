export default async function({ route, redirect, store, env, process }) {
    // const isTestStage = store.getters['domain/getIsTestStage'];
    //
    // if (!isTestStage && !env.DEVELOPMENT) {
    //     redirect('/soon');
    // }

    if (store.state?.settings?.technical_work) {
        redirect('/maintenance');
    } else if (route.path === '/maintenance') {
        redirect('/');
    }

    if (!store.state?.user?.user) {
        await store.dispatch('user/fetchUser');
    }

    // Получаем информацию о пользователе
    const user = store.state?.user?.user;

    // Редиректы в зависимости от условий запроса и пользователя
    if (route.path === '/job' || route.path.includes('/job/resume') && !user?.is_company) {
        redirect('/job/vacancy');
    }
}
